import React from 'react';

function SubscribeForm() {
  return (
    <div>
      <div className='container px-12 absolute bottom-[-480px] lg:bottom-[-100px] mb-12 lg:mb-6'>
        <div className='px-10 py-8 bg-white drop-shadow-2xl rounded-lg flex flex-col lg:flex-row items-center m-auto'>
          <h2 className='mb-6 text-dark-green flex-1 text-center lg:mb-0 lg:text-left'>
            Subscribe to our newsletter to receive updates on our properties
          </h2>

          <form className='flex-1 ml-2 text-center lg:text-left' action=''>
            <input
              type='text'
              className='mb-4 rounded-full text-dark-green bg-light-gray py-3 px-8 w-96 mr-2 hover:bg-dark-green lg:mb-0'
            />
            <button
              className='bg-sky-blue hover:bg-dark-green py-3 px-9 rounded-full text-white font-bold'
              type='submit'>
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SubscribeForm;
