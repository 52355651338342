import React from 'react';

const Bookings = () => {
  return (
    <div>
      <div className='container flex flex-col justify-center items-center my-12'>
        <div className='text-center p-20 bg-white drop-shadow-lg'>
          <h1>Make your reservations</h1>
          <p className='mb-12'>Fill in the form below</p>

          <form className='space-y-6'>
            <div className='flex flex-col items-start'>
              <label className='font-bold' htmlFor='date'>
                Name
              </label>
              <input
                className='border-2 border-gray rounded-xl px-3 w-[400px] h-12'
                type='text'
                name='name'
                placeholder='Enter your full name'
                id='name'
              />
            </div>

            <div className='flex flex-col items-start'>
              <label className='font-bold' htmlFor='date'>
                Email
              </label>
              <input
                className='border-2 border-gray rounded-xl px-3 w-[400px] h-12'
                type='email'
                name='email'
                placeholder='Enter your email address'
                id='email'
              />
            </div>

            <div className='flex flex-row space-x-2'>
              <div className='flex flex-col items-start'>
                <label className='font-bold' htmlFor='date'>
                  Check in
                </label>
                <input
                  className='border-2 border-gray rounded-xl px-3 w-[200px] h-12'
                  type='date'
                  name='date'
                  id='date'
                />
              </div>

              <div className='flex flex-col items-start'>
                <label className='font-bold' htmlFor='date'>
                  Check out
                </label>
                <input
                  className='border-2 border-gray rounded-xl px-3 w-[200px] h-12'
                  type='date'
                  name='date'
                  id='date'
                />
              </div>
            </div>

            <button
              className='bg-sky-blue py-3 px-4 w-full rounded-xl text-white hover:bg-dark-blue'
              type='submit'>
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Bookings;
