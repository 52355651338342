import React from 'react';

function SignUp() {
  return (
    <div>
      <div className='container flex flex-col justify-center items-center my-12'>
        <div className='text-center p-20 bg-white drop-shadow-lg'>
          <h1>SignUp</h1>
          <p className='mb-12'>Fill in your information down below</p>

          <form className='flex flex-col space-y-6'>
            <input
              className='border-2 border-gray rounded-xl px-3 w-[400px] h-12'
              type='name'
              placeholder='Fullname'
              name='fullname'
              id='fullname'
            />

            <input
              className='border-2 border-gray rounded-xl px-3 w-[400px] h-12'
              type='email'
              placeholder='Email'
              name='email'
              id='email'
            />

            <input
              className='border-2 border-gray rounded-xl px-3 w-[400px] h-12'
              type='password'
              placeholder='Password'
              name='password'
              id='password'
            />

            <button className='bg-sky-blue py-3 rounded-xl' type='submit'>
              Continue
            </button>
          </form>

          <p className='mt-6'>
            Already have an account?{' '}
            <span className='text-sky-blue'>Login</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
