import React from 'react';
import { Link } from 'react-router-dom';
import SubscribeForm from '../components/SubscribeForm';
import WifiIcon from '@mui/icons-material/Wifi';
import SecurityIcon from '@mui/icons-material/Security';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import Fullbuilding from '../images/FULLBUILDING.png';

function Home() {
  return (
    <div>
      <div className='min-h-[650px] bg-gradient-to-r from-black to-dark-blue relative'>
        <div className='container px-12 md:px-32 mx-auto flex flex-col lg:flex-row items-center pt-28'>
          <div className='text-white lg:mr-20'>
            <h1 className='text-[48px] md:text-[64px] text-center leading-none lg:text-left'>
              Get your dream space today!!!
            </h1>
            <p className='my-6 text-gray text-center lg:mt-6 lg:text-left'>
              Rent comforatable and beautiful houses in so many places
            </p>
          </div>
          <img
            className='bg-auto rounded-[55px] mb-80 lg:mb-0 min-w-[500px] max-h-[400px] lg:w-2/4'
            src={Fullbuilding}
            alt=''
          />
        </div>
      </div>
      {/* <SubscribeForm /> */}

      <section
        id='services'
        className='container px-12 lg:px-32 mx-auto items-center my-32'>
        <h1 className='text-[48px] leading-10 lg:text-[64px] text-center mx-auto mb-6'>
          Our Services
        </h1>
        <p className='text-center mx-auto tracking-[4px] underline underline-offset-8'>
          What you stand to benefit from our services
        </p>

        <div className='flex flex-col text-center lg:flex-row mt-10'>
          <div className='p-8 mb-6 max-w-[700px] lg:mb-0 lg:mr-12 rounded-xl text-center text-white bg-gradient-to-r from-black to-dark-blue'>
            <WifiIcon />
            <h3>Unlimited Wifi</h3>
            <p className='text-gray'>
              Enjoy your stay whilst streaming on the go
            </p>
          </div>

          <div className='p-8 mb-6 lg:mb-0 lg:mr-12 rounded-xl text-center text-white bg-gradient-to-r from-black to-dark-blue'>
            <SecurityIcon />
            <h3>Security</h3>
            <p className='text-gray'>
              Security cameras and officers with officers on patrol
            </p>
          </div>

          <div className='p-8 rounded-xl text-center text-white bg-gradient-to-r from-black to-dark-blue'>
            <LocalParkingIcon />
            <h3>Parking</h3>
            <p className='text-gray'>Free parking on premises</p>
          </div>
        </div>
      </section>

      <section className='slanted-div bg-gradient-to-r from-black to-dark-blue shadow-inner'>
        <div className='container px-12 lg:px-32 mx-auto flex flex-col lg:flex-row items-center text-center lg:text-left'>
          <img
            className='rounded-[30px] mb-12 lg:mb-0 lg:rounded-[55px] w-full lg:w-2/4 lg:mr-6'
            src='https://images.unsplash.com/photo-1614023342667-6f060e9d1e04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YmxhY2slMjBtYW4lMjBzbWlsaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60'
            alt=''
          />
          <div className='text-white'>
            <h1 className='text-[48px] lg:text-[64px] leading-none'>
              We give you the best experience
            </h1>
            <p className='mt-6 text-gray'>
              Steady power supply. Never get bored with the availability of
              constant electricity. We also have a generator on standby to
              ensure steady electricity.
            </p>

            {/* <div className='bg-white pt-[0.5px] my-6'></div> */}
            {/* <div className='flex lg:space-x-14 justify-between'>
              <div>
                <h1 className='text-sky-blue text-4xl'>180+</h1>
                <p className='text-gray'>Happy clients</p>
              </div>

              <div>
                <h1 className='text-sky-blue text-4xl'>180+</h1>
                <p className='text-gray'>Happy clients</p>
              </div>

              <div>
                <h1 className='text-sky-blue text-4xl'>180+</h1>
                <p className='text-gray'>Happy clients</p>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className='container px-12 lg:px-32 mx-auto items-center my-32 text-center'>
        <h1 className='text-[48px] lg:text-[64px]'>Simple Step, big move</h1>
        <p className='my-8'>
          Look for an apartment that you like, check the price and location to
          see if it suits you. It’s that easy. Use the following steps below to
          find the apartment that’s right for you
        </p>

        <Link
          to='/api/apartments'
          className='bg-sky-blue py-3 px-4 w-full rounded-full text-white hover:bg-dark-blue'
          style={{ textDecoration: 'none' }}>
          Get your apartment
        </Link>
      </section>
    </div>
  );
}

export default Home;
