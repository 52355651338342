// import React, { useContext, useState } from 'react';
import RoomIcon from '@mui/icons-material/Room';
// import useFetch from '../hooks/useFetch';
// import { AuthContext } from '../context/authContext';
import { useNavigate } from 'react-router-dom';
import Bedroom from '../images/BEDROOM.png';
import Fullbuilding from '../images/FULLBUILDING.png';
import Sittingroom2 from '../images/SITTINGROOM_2.png';
import Sittingroom1 from '../images/SITTINGROOM.png';
import Staircase1 from '../images/STAIRCASE1.png';
import Staircase2 from '../images/STAIRCASE2.png';

const Apartments = () => {
  // const { data, loading, error } = useFetch('/apartments');
  // // const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClick = e => {
    navigate('/contact');
  };

  return (
    <div>
      {/* {loading ? (
        'Loading...'
      ) : (
        <> */}
      {/* {data.map((item, i) => ( */}
      <div
        className='container px-12 lg:px-32 mx-auto items-center my-12'
        /* key={i} */
      >
        <div className='flex flex-col text-center lg:text-left lg:flex-row lg:items-start lg:justify-between'>
          <div className='mb-6'>
            <h1 className='mb-2 text-[30px]'>
              {/* {item.name} */} house14apartments
            </h1>
            <p>
              <RoomIcon className='mr-2' />
              {/* {item.address} */} 16 Ibrahimo Street, Odi Olowo, Mushin,
              Lagos
            </p>

            <p className='text-sky-blue'>
              Excellent location in with good accessible roads
            </p>
          </div>
          <button
            onClick={handleClick}
            className='mb-6 bg-dark-blue text-white py-3 px-4 rounded-full lg:mb-0 hover:bg-sky-blue'
            type='submit'>
            Reserve or Book Now!
          </button>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 place-items-center'>
          <div className='w-[350px] lg:w-auto'>
            <img src={Bedroom} alt='' />
          </div>
          <div className='w-[350px] lg:w-auto'>
            <img src={Fullbuilding} alt='' />
          </div>
          <div className='w-[350px] lg:w-auto'>
            <img src={Sittingroom1} alt='' />
          </div>
          <div className='w-[350px] lg:w-auto'>
            <img src={Sittingroom2} alt='' />
          </div>
          <div className='w-[350px] lg:w-auto'>
            <img src={Staircase1} alt='' />
          </div>
          <div className='w-[350px] lg:w-auto'>
            <img src={Staircase2} alt='' />
          </div>
        </div>

        <div className='flex flex-col space-y-3 text-center mt-6 space-x-6 lg:flex-row lg:text-left'>
          <div>
            <h1 className='text-[30px] mt-6 mb-3'>
              A new luxury serviced apartment
            </h1>
            <p>
              A new luxury serviced apartment in 16 Ibrahimo Street, Odi Olowo,
              Mushin, Lagos. This is one of the best locations for you on and of
              time with premium residence with only 5 units in the building. It
              is situated in a comfortable space surrounded by high quality
              design and various services.
            </p>
          </div>
          <div className='p-6 bg-sky-blue bg-opacity-10'>
            <h2 className='mb-3'>Get this apartment today</h2>
            <p className='mb-3'>
              Steady power supply. Never get bored with the availability of
              constant electricity. We also have a generator on standby to
              ensure steady electricity.
            </p>
            <h1 className='mb-3'>N30k per room</h1>
            <button
              onClick={handleClick}
              className='bg-dark-blue text-white py-3 px-4 rounded w-full hover:bg-sky-blue'
              type='submit'>
              Reserve or Book Now!
            </button>
          </div>
        </div>
      </div>
      {/* ))} */}
      {/* </> */}
      {/* )} */}
    </div>
  );
};

export default Apartments;
