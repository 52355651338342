import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { AuthContext } from '../context/authContext';

function Header() {
  const [displayMenu, setDisplayMenu] = useState(false);

  const { user } = useContext(AuthContext);

  function showMenu() {
    if (displayMenu) {
      setDisplayMenu(false);
    } else {
      setDisplayMenu(true);
    }
  }

  return (
    <div className='container px-12 md:px-32 py-4 mx-auto text-white'>
      <div className='flex items-center place-content-between'>
        <h1>house14apartments</h1>
        <div className='lg:hidden'>
          <MenuIcon onClick={showMenu}></MenuIcon>
        </div>
        <div className='hidden place-content-between items-center lg:flex'>
          <Link
            to='/'
            style={{ textDecoration: 'none' }}
            className='md:pr-6 cursor-pointer text-white hover:text-sky-blue'>
            Home
          </Link>
          <Link
            to='/api/apartments'
            style={{ textDecoration: 'none' }}
            className='md:pr-6 cursor-pointer text-white hover:text-sky-blue'>
            Apartments
          </Link>
          <Link
            to='/contact'
            style={{ textDecoration: 'none' }}
            className='md:pr-6 cursor-pointer text-white hover:text-sky-blue'>
            Contact
          </Link>
          {!user ? (
            <Link
              to='/login'
              style={{ textDecoration: 'none' }}
              className='p-1 px-4 rounded-full bg-sky-blue cursor-pointer hover:bg-[#ccc] text-white'>
              Login
            </Link>
          ) : (
            <h1 className='text-[18px] text-sky-blue'>{user.username}</h1>
          )}
        </div>
      </div>

      <div
        className={
          displayMenu
            ? 'bg-white text-black space-y-4 pt-2 text-center flex flex-col'
            : 'hidden'
        }>
        <Link
          style={{ textDecoration: 'none' }}
          to='/'
          className='md:pr-6 cursor-pointer hover:text-gray'>
          Home
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          to='/api/apartments'
          className='md:pr-6 cursor-pointer hover:text-gray'>
          Apartments
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          to='/contact'
          className='md:pr-6 cursor-pointer hover:text-gray'>
          Contact
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          to='/login'
          className='p-1 px-4 bg-sky-blue text-white cursor-pointer hover:bg-dark-green hover:text-gray'>
          <p>Login</p>
        </Link>
      </div>
    </div>
  );
}

export default Header;
