import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './screens/Home';
import Login from './screens/Login';
import SignUp from './screens/SignUp';
import Apartments from './screens/Apartments';
import Bookings from './screens/Bookings';
import Contact from './screens/Contact';

function App() {
  return (
    <Router>
      <div className='bg-gradient-to-r from-black to-dark-blue'>
        <Header />
      </div>

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/bookings' element={<Bookings />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/api/apartments' element={<Apartments />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
