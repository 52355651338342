import React from 'react';

const Contact = () => {
  return (
    <div>
      <div className='container flex flex-col justify-center items-center my-12'>
        <div className='p-20 bg-white drop-shadow-lg'>
          <h1 className='text-[40px] lg:text-[80px] mb-12'>Contact Us</h1>
          <h2>Call Us/ Send a Message:</h2>
          <p className='mb-6'>0901 948 0655</p>

          <h2>Send us a mail:</h2>
          <p className='mb-6'>
            <a href='mailto:house14apartments@gmail.com'>
              house14apartment@gmail.com
            </a>
          </p>

          <h2>Facebook</h2>
          <p>@house14apartments</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
