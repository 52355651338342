import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext';

function Login() {
  const [credentials, setCredentials] = useState({
    username: undefined,
    password: undefined,
  });

  const { loading, error, dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleChange = e => {
    setCredentials(prev => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async e => {
    e.preventDefault();
    dispatch({ type: 'LOGIN_START' });
    try {
      const res = await axios.post('/auth/login', credentials);
      dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
      navigate('/');
    } catch (err) {
      dispatch({ type: 'LOGIN_FAILURE', payload: err.response.data });
    }
  };

  return (
    <div>
      <div className='container flex flex-col justify-center items-center my-12'>
        <div className='text-center p-20 bg-white drop-shadow-lg'>
          <h1>Login</h1>
          <p className='mb-12'>Fill in your information down below</p>

          <form className='flex flex-col space-y-6'>
            <input
              className='border-2 border-gray rounded-xl px-3 w-[400px] h-12'
              type='email'
              placeholder='Username'
              name='username'
              id='username'
              onChange={handleChange}
            />

            <input
              className='border-2 border-gray rounded-xl px-3 w-[400px] h-12'
              type='password'
              placeholder='Password'
              name='password'
              id='password'
              onChange={handleChange}
            />

            <button
              disabled={loading}
              onClick={handleClick}
              className='bg-sky-blue py-3 rounded-xl'
              type='submit'>
              Login
            </button>
            {error && <span>{error.message}</span>}
          </form>

          <p className='mt-6'>
            Don't have an account yet?{' '}
            <span className='text-sky-blue'>Sign Up</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
