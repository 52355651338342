import { React } from 'react';

function Footer() {
  return (
    <div className='bg-gradient-to-r from-black to-dark-blue pb-6 bottom-0 w-full'>
      <div className='container px-12 pt-4 mx-auto text-white lg:px-32 md:w-full'>
        <h1>house14apartments</h1>
        <div className='flex place-content-between'>
          <div className='space-y-6 mt-6 place-content-between items-center lg:flex'>
            <p className='md:pr-6 cursor-pointer mt-6 hover:text-sky-blue'>
              Home
            </p>
            <p className='md:pr-6 cursor-pointer hover:text-sky-blue'>About</p>
            <p className='md:pr-6 cursor-pointer hover:text-sky-blue'>
              Apartments
            </p>
            <p className='md:pr-6 cursor-pointer hover:text-sky-blue'>
              Contact
            </p>
          </div>
          <div className='space-y-6 mt-6 place-content-between items-center lg:flex'>
            <p className='md:pr-6 cursor-pointer mt-6 hover:text-sky-blue'>
              Instagram
            </p>
            <a
              href='https://www.facebook.com/profile.php?id=100089452000329'
              style={{ textDecoration: 'none' }}
              className='md:pr-6 cursor-pointer text-white hover:text-sky-blue'>
              Facebook
            </a>
            <p className='md:pr-6 cursor-pointer hover:text-sky-blue'>
              Pinterest
            </p>
            <a
              href='https://wa.me/%3C08038028165%3E'
              style={{ textDecoration: 'none' }}
              className='md:pr-6 cursor-pointer text-white hover:text-sky-blue'>
              WhatsApp
            </a>
          </div>
        </div>

        <p className='mt-8 text-sky-blue'>
          &copy; 2022 All rights reserved. house14apartments
        </p>
      </div>
    </div>
  );
}

export default Footer;
